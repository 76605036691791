<template>
  <div>
    <b-card
      v-if="isEdit && currentUser.accountType === 'system_admin'"
      class="card-custom mb-7"
    >
      <h6 class="mb-7">Application Status</h6>
      <b-row>
        <b-button
          v-if="application"
          :disabled="isSubmitting"
          @click="updateStatus('APPROVED', 'approvedButton')"
          ref="approvedButton"
          :variant="application.status === 'APPROVED' ? 'success' : 'secondary'"
          class="font-weight-bold col mr-2"
          >APPROVED</b-button
        >
        <b-button
          v-if="application"
          :disabled="isSubmitting"
          @click="updateStatus('PENDING', 'pendingButton')"
          ref="pendingButton"
          :variant="application.status === 'PENDING' ? 'success' : 'secondary'"
          class="font-weight-bold col mr-2"
          >PENDING</b-button
        >
        <b-button
          v-if="application"
          :disabled="isSubmitting"
          @click="updateStatus('DELETED', 'deletedButton')"
          ref="deletedButton"
          :variant="application.status === 'DELETED' ? 'success' : 'secondary'"
          class="font-weight-bold col mr-2"
          >DELETED</b-button
        >
        <b-button
          v-if="application"
          :disabled="isSubmitting"
          @click="updateStatus('REJECTED', 'rejectedButton')"
          ref="rejectedButton"
          :variant="application.status === 'REJECTED' ? 'success' : 'secondary'"
          class="font-weight-bold col mr-2"
          >REJECTED</b-button
        ></b-row
      >
    </b-card>

    <b-card
      class="card-custom"
      :title="isEdit ? 'Update Application' : 'Create Application'"
      :sub-title="`Please complete to form below to
            ${isEdit ? 'update' : 'submit'} your application`"
      sub-title-tag="p"
    >
      <hr class="mt-7" />
      <!-- <div class="card-header mb-7">
        <div class="card-title mb-0">
          <h3 class="card-label text-uppercase">
            {{ isEdit ? "Update application" : "Create Application" }}
          </h3>
          <p class="text-info">
            Please complete to form below to
            {{ isEdit ? "update" : "submit" }} your application
          </p>
        </div>
      </div> -->
      <b-form @submit.prevent="onSubmit">
        <div class="row">
          <b-form-group class="mb-3 col-md-4" label="Application Name:">
            <b-form-input
              :disabled="isSubmitting"
              v-model="form.appName"
              required
              placeholder="Enter app name"
            ></b-form-input>
          </b-form-group>

          <div class="col-md-4">
            <div class="form-group">
              <div v-if="!isEdit || isUploadNewIcon" class="custom-file">
                <b-form-group class="mt-0 mb-3" label="Icon:">
                  <b-form-file
                    class="mb-3"
                    :disabled="isSubmitting"
                    v-model="form.icon"
                    accept="image/*"
                    :state="Boolean(form.icon)"
                    placeholder="Choose a file"
                    drop-placeholder="Drop file here..."
                    required
                  >
                    <template slot="file-name" slot-scope="{ names }">
                      <b-badge variant="dark">{{ names[0] }}</b-badge>
                    </template></b-form-file
                  >
                  <div class="mb-3 text-muted small">
                    Allow file dimensions are:
                    <font class="font-weight-bold text-warning">512x512</font>
                  </div>
                </b-form-group>
              </div>
              <div v-else>
                <label for="companyLogo">Icon:</label><br />
                <div class="d-flex">
                  <img
                    width="70px"
                    height="70px"
                    style="
                      max-height: 70px;
                      object-fit: cover;
                      border-radius: 50%;
                    "
                    v-bind:src="`${SERVER_URL}/api/v1/media?mediaType=icon&id=${applicationId}`"
                    alt="Logo"
                  />
                  <a
                    class="pl-3"
                    href="javascript:void(0);"
                    @click="removeImage"
                  >
                    &nbsp;<i class="mdi mdi-close"></i>
                  </a>
                </div>
              </div>
              <div class="valid-feedback">Looks good!</div>
            </div>
          </div>

          <b-form-group class="mb-0 col-md-4" label="APK:">
            <b-input-group v-if="isUploadNewAPK">
              <b-form-file
                class="mt-0 mb-3"
                v-model="form.apk"
                :disabled="isSubmitting"
                accept=".apk"
                :state="Boolean(form.apk)"
                placeholder="Choose an apk file"
                drop-placeholder="Drop file here..."
                required
              >
                <template slot="file-name" slot-scope="{ names }">
                  <b-badge variant="dark">{{ names[0] }}</b-badge>
                </template></b-form-file
              >
            </b-input-group>
            <div v-else>
              <div v-if="!application">
                <b-form-file
                  class="mt-0 mb-3"
                  v-model="form.apk"
                  :disabled="isSubmitting"
                  accept=".apk"
                  :state="Boolean(form.apk)"
                  placeholder="Choose an apk file"
                  drop-placeholder="Drop file here..."
                  required
                >
                  <template slot="file-name" slot-scope="{ names }">
                    <b-badge variant="dark">{{ names[0] }}</b-badge>
                  </template></b-form-file
                >

                <div class="mb-5 text-muted small">
                  Please add a valid production apk for your application.
                  Allowed file size
                  <font class="font-weight-bold text-warning">128mb</font>
                </div>
              </div>
              <b-input-group v-else-if="application">
                <b-form-input
                  disabled
                  :value="`v${
                    application !== null ? application.versionName : ''
                  }`"
                ></b-form-input>
                <b-input-group-append @click="uploadApk">
                  <b-button variant="outline-info" ref="uploadApkButton"
                    ><i class="la la-upload"></i> Upload New APK</b-button
                  >
                </b-input-group-append>
                <b-input-group-append @click="downloadApk">
                  <b-button variant="outline-success" ref="downloadApkButton"
                    ><i class="la la-download"></i> Download</b-button
                  >
                </b-input-group-append>
              </b-input-group>
              <div class="mt-2 float-right text-muted">
                <font class="font-weight-bold text-warning">{{
                  humanFileSize(application !== null ? application.size : 0)
                }}</font>
              </div>
            </div>
          </b-form-group>

          <b-form-group class="mt-0 mb-3 col-md-4" label="Privacy URL:">
            <b-form-input
              v-model="form.privacyPolicy"
              :disabled="isSubmitting"
              required
              placeholder="Enter app name"
              accept="apk"
            ></b-form-input>
          </b-form-group>

          <b-form-group class="mt-0 mb-3 col-md-4" label="Category:">
            <b-form-select
              v-model="form.categoryId"
              :disabled="isSubmitting"
              :options="[
                {
                  text: 'Please select an option',
                  value: null,
                  disabled: true,
                },
                ...categories.map((c) => ({
                  value: c._id,
                  text: c.categoryName,
                })),
              ]"
            >
            </b-form-select>
          </b-form-group>

          <div class="col-md-12 mb-3"></div>
          <b-form-group class="mt-0 mb-3 col" label="Description:">
            <b-form-textarea
              required
              :disabled="isSubmitting"
              v-model="form.description"
              placeholder="Enter your application description"
              rows="3"
              max-rows="8"
            ></b-form-textarea>
          </b-form-group>
        </div>

        <div class="col mt-5">
          <b-button type="submit" ref="submitApplication" variant="primary"
            >{{ isEdit ? "Update" : "Submit" }} <i class="la la-check"></i
          ></b-button>
        </div>
      </b-form>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { GET_CATEGORIES } from "@/core/services/store/categories.module";
import { DOWNLOAD_APK } from "@/core/services/store/downloads.module";
import { humanFileSize, SERVER_URL } from "@/utils";
import {
  ADD_APPLICATION,
  GET_APPLICATION,
  UPDATE_APPLICATION,
  UPDATE_APPLICATION_STATUS,
} from "@/core/services/store/applications.module";

import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";

import { getObjectDifferences } from "@/utils";

export default {
  mixins: [validationMixin],
  data() {
    return {
      SERVER_URL,
      isUploadNewAPK: false,
      isUploadNewIcon: false,
      humanFileSize,
      isDownloading: false,
      isEdit: false,
      isSubmitting: false,
      applicationId: null,
      defaultApplication: null,
      form: {
        appName: null,
        description: null,
        icon: null,
        apk: null,
        privacyPolicy: null,
        categoryId: null,
      },
    };
  },
  created() {
    this.isEdit = new String(this.$router.history.current.fullPath).includes(
      "edit"
    );
    this.applicationId = this.$router.history.current.params.id;

    this.getCategories();
    if (this.isEdit) {
      this.getApplication();
    }
  },
  validations: {
    form: {
      appName: {
        required,
        minLength: minLength(2),
      },
      description: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    uploadApk() {
      this.isUploadNewAPK = true;
    },
    downloadApk() {
      if (!this.isDownloading) {
        const submitButton = this.$refs["downloadApkButton"];
        submitButton.classList.add(
          "spinner",
          "spinner-light",
          "disabled",
          "spinner-right"
        );
        this.$store
          .dispatch(DOWNLOAD_APK, {
            params: { appId: this.applicationId },
            fileName: `diapps-${this.application.appName}-v${this.application.versionName}.apk`,
          })
          .then(() => {
            this.$bvToast.toast("Your download has been started.", {
              title: "Download started",
              variant: "success",
              solid: true,
              appendToast: true,
            });
          })
          .catch((e) => {
            this.$bvToast.toast(e || "Failed to download APK.", {
              title: "Download failed",
              variant: "danger",
              solid: true,
              appendToast: true,
            });
          })
          .finally(() => {
            this.isDownloading = false;
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "disabled",
              "spinner-right"
            );
          });
      }
    },
    updateStatus(status) {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      this.$store
        .dispatch(UPDATE_APPLICATION_STATUS, {
          applicationId: this.applicationId,
          userId: this.currentUser._id,
          status,
        })
        .then(() => {
          this.$bvToast.toast(
            "Your application has successfully been updated",
            {
              title: "Successfully updated",
              variant: "success",
              solid: true,
              appendToast: true,
            }
          );
        })
        .catch((e) => {
          this.$bvToast.toast(e || "Failed to update application status.", {
            title: "Update failed",
            variant: "danger",
            solid: true,
            appendToast: true,
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    getApplication() {
      this.$store
        .dispatch(GET_APPLICATION, {
          applicationId: this.applicationId,
          userId: this.currentUser._id,
        })
        .then(() => {
          if (this.application)
            this.form = {
              appName: this.application.appName,
              description: this.application.description,
              // icon: this.application.iconUrl,
              // apk: this.application.appName,
              privacyPolicy: this.application.privacyPolicy,
              categoryId: this.application.categoryId
                ? this.application.categoryId._id
                : "-",
            };
          else this.$router.push("applications");
        })
        .catch(console.log);
    },
    getCategories() {
      // this.isLoading = true;
      this.$store
        .dispatch(GET_CATEGORIES)
        .then(() => {})
        .catch(console.log);
    },
    // validateState(name) {
    //   const { $dirty, $error } = this.$v.form[name];
    //   return $dirty ? !$error : null;
    // },
    onSubmit() {
      if (this.isSubmitting) return;
      // this.$v.form.$touch();
      // if (this.$v.form.$anyError) {
      //   return;
      // }

      this.isSubmitting = true;
      // set spinner to submit button
      const submitButton = this.$refs["submitApplication"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      if (this.isEdit) {
        this.defaultApplication = getObjectDifferences();
      }

      const formData = new FormData();
      formData.append("appName", this.form.appName);
      formData.append("description", this.form.description);
      formData.append("icon", this.form.icon);
      formData.append("apk", this.form.apk);
      formData.append("privacyPolicy", this.form.privacyPolicy);
      formData.append("categoryId", this.form.categoryId);
      formData.append("organisation", this.currentUser.organisation._id);
      formData.append("requestBy", this.currentUser._id);
      if (this.isEdit) formData.append("appId", this.applicationId);

      if (this.isEdit) {
        this.$store
          .dispatch(UPDATE_APPLICATION, formData, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          // go to which page after successfully login
          .then((a) =>
            this.$bvToast.toast(
              "Your application has successfully been updated",
              {
                title: "Successfully submitted",
                variant: "success",
                solid: true,
                appendToast: true,
              }
            )
          )
          .catch((e) => {
            this.$bvToast.toast(
              e || "Failed to update to update application details.",
              {
                title: "Submission failed",
                variant: "danger",
                solid: true,
                appendToast: true,
              }
            );
          })
          .finally(() => {
            (this.isSubmitting = false), (this.isUploadNewAPK = false);
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        this.$store
          .dispatch(ADD_APPLICATION, formData, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          // go to which page after successfully login
          .then((a) =>
            this.$bvToast.toast(
              "Your application has successfully been submitted",
              {
                title: "Successfully submitted",
                variant: "success",
                solid: true,
                appendToast: true,
              }
            )
          )
          .catch((e) => {
            this.$bvToast.toast(e, {
              title: "Submission failed",
              variant: "danger",
              solid: true,
              appendToast: true,
            });
          })
          .finally(() => {
            this.isSubmitting = false;
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },
    removeImage() {
      this.isUploadNewIcon = true;
    },
  },
  computed: {
    ...mapGetters(["categories", "application", "currentUser"]),
  },
};
</script>